/* eslint-disable @typescript-eslint/promise-function-async */
import loadable from '@loadable/component';

global.Components = {
  FeaturedBrandsSlider: loadable(() => import('./features/FeaturedBrands'), {
    resolveComponent: x => x.FeaturedBrandsWrapped as any
  }),
  Footer: loadable(() => import('./features/Footer'), {
    resolveComponent: x => x.FooterWrapped as any
  }),
  TwoStepVerificationElement: loadable(() => import('./features/TwoStepVerification'), {
    resolveComponent: x => x.TwoStepVerificationWrapped as any
  })
};

global.HomePage = {
  Index: loadable(() => import('./pages/Home/Home'), {
    resolveComponent: x => x.HomeWrapped as any
  }),
  NewsletterSection: loadable(() => import('./features/NewsletterSection'), {
    resolveComponent: x => x.NewsletterSectionWrapped as any
  }),
  HeroBanner: loadable(() => import('./features/HeroBanner'), {
    resolveComponent: x => x.HeroBannerWrapped as any
  }),
  QuickLinks: loadable(() => import('./features/QuickLinks'), {
    resolveComponent: x => x.QuickLinksWrapped as any
  })
};

global.MyAccountPage = {
  CloseAccountPage: loadable(() => import('./pages/CloseAccount'), {
    resolveComponent: x => x.CloseAccountWrapped as any
  })
};

global.EdrLanding = {
  Index: loadable(() => import('./pages/EdrLanding/EdrLanding'), {
    resolveComponent: x => x.EdrLandingWrapped as any
  })
};
