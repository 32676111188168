import { loadableReady } from '@loadable/component';
// eslint-disable-next-line import/no-webpack-loader-syntax
import 'expose-loader?exposes=React!react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import 'expose-loader?exposes=ReactDOM!react-dom';
import './components';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadableReady(() => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
  for (const invokeCallback of (window as any).loadableReadyCallbacks as Array<
  () => void
  >) {
    invokeCallback();
  }
});
